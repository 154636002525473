import { Item } from "../../../models/Item";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  IconButton,
  Stack,
  SvgIcon,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useEffect, useState } from "react";
import ItemSearch from "./ItemSearch";
import { useTranslation } from "react-i18next";
import { padding } from "@mui/system";

import style from "./style.module.css";

interface Props {
  items: Item[];
  onEdit: (item: Item) => void;
  onDelete: (itemId: string) => void;
  onSearchItem: (items: Item[]) => void;
}

const ItemTable: React.FC<Props> = ({
  items,
  onDelete,
  onEdit,
  onSearchItem,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<Item[]>(items);

  // const handleUpdateOrDelete = () => {
  //     const filteredRows = items.filter((row) => {
  //         return row.dataIndicatorName.toLowerCase().includes(searched.toLowerCase()
  //         ) || row.hditName.toLowerCase().includes(searched.toLowerCase());
  //     });

  //     setFilteredItems(filteredRows)
  // }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);

    const filteredRows = items.filter((row) => {
      return (
        row.dataIndicatorName
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.hditName.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });

    setFilteredItems(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <div className={style.container}>
      <ItemSearch
        onCancelSearch={cancelSearch}
        onRequestSearch={requestSearch}
      />
      <TableContainer>
        <Table className={style.addItem}>
          <TableHead>
            <TableRow>
              <TableCell width="5%"></TableCell>
              <TableCell width="25%">
                <b>{t("hdd_table_head_data_indicatior_name")}</b>
              </TableCell>
              <TableCell width="25%">
                <b>{t("hdd_table_head_technical_name")}</b>
              </TableCell>
              <TableCell width="45%">
                <b>{t("hdd_table_head_definition")}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(filteredItems.length > 0 || searched != ""
              ? filteredItems
              : items
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <IconButton onClick={() => onEdit(item)}>
                      <EditIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row" color="">
                    {item.dataIndicatorName}
                  </TableCell>
                  <TableCell>{item.technicalName}</TableCell>
                  <TableCell>
                    {item.definition.length > 100
                      ? `${item.definition.substring(0, 100)}...`
                      : item.definition}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredItems.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage={t("hdd_table_rows_per_page")}
        labelDisplayedRows={({ from, to, count }) => {
          return (
            "" +
            from +
            "-" +
            to +
            " " +
            t("hdd_table_label_displayed_rows") +
            " " +
            count
          );
        }}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ItemTable;
