import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../components/hdd-documents/home/Home";
import Search from "../components/term-service/Search";
import "antd/dist/antd.css";
import SideBar from "../components/SideBar";
import MyHome from "../pages/home";
import css from "./Style.module.css";
import HealthData from "../pages/HealthData";
import NationalIndicators from "../pages/NationalIndicators";
import Icd10 from "../pages/Icd10";
import Icd9 from "../pages/Icd9";
import MyHeader from "../pages/Header";
import Footer from "../pages/Footer";
import Loinc from "../pages/Loinc";
import Snomed from "../pages/Snomed";
import MonHdd from "../pages/MonHdd";

function AppRouter() {
  return (
    <>
      <MyHeader />
      <Router>
        {/* <SideBar />ß */}
        <Routes>
          {/* <Route path="/health_data" element={<MyHome />}></Route> */}
          <Route path="/" element={<MyHome />}></Route>
          <Route path="/hdd_add_item" element={<Home />}></Route>
          {/* <Route path="/national_indicators" element={<NationalIndicators />}></Route>
              <Route path="/icd10" element={<Icd10 />}></Route>
              <Route path="/icd9" element={<Icd9 />}></Route>
              <Route path="/loinc" element={<Loinc />}></Route>
              <Route path="/snomed" element={<Snomed />}></Route>
              <Route path="/monhdd" element={<MonHdd />}></Route> */}
          <Route path="/health_data" element={<HealthData />}></Route>
          <Route path="*" element={<HealthData />}></Route>
          {/* <Route path="/term-service" element={<Search />}></Route> */}
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default AppRouter;
