import { useTranslation } from "react-i18next";
import "./style.css";
import DownloadButton from "../buttons/DownloadButton";

interface listProps {
  children?: React.ReactNode;
  onDownloadPDF: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DataSet: React.FC<listProps> = ({ children, onDownloadPDF }) => {
  const { t } = useTranslation();

  return (
    <div className={"flexContainer"}>
      <div>
        <DownloadButton onDownloadPDF={onDownloadPDF} />
      </div>
      <p className="dataSetText">{t(`${children}`)}</p>
    </div>
  );
};

export default DataSet;
