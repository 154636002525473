import { Link }from 'react-router-dom'
import "./style.css";

interface Props {
  children?: React.ReactNode;
  path: string,
}

const CyanButton: React.FC<Props> = ({
  children,
  path,
}) => {
  return (
          <div 
            className={"cyan" }
          >
            <Link to={path}>
              <p className= {"cyanText"}>{children}</p>
            </Link>
          </div>
  );
};

export default CyanButton;
