import { useTranslation } from "react-i18next";
import DownloadButton from "../buttons/DownloadButton";

interface listProps {
  name: string;
  code: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onDownloadPDF: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const HealthDataList: React.FC<listProps> = ({
  onDownloadPDF,
  onClick,
  name,
  code,
}) => {
  const { t } = useTranslation();

  return (
    <div className={"flexbox"}>
      <button onClick={onClick} className={"data"}>
        <div className={"flexbox"}>
          <p className={"firstText"}>{name}</p>
          <p className={"SecondText"}>{code}</p>
        </div>
      </button>
      <div className={"downloadButton"}>
        <DownloadButton onDownloadPDF={onDownloadPDF} />
      </div>
    </div>
  );
};

export default HealthDataList;
