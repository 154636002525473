import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { getPDF, printPDF, updateItem } from "../../../services/data";

import "./Form.css";
import { Item } from "../../../models/Item";
import { Button, Stack, TextareaAutosize, Input } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTranslation } from "react-i18next";

interface Props {
  editItem: Item;
  onUpdateItem: (id: string, item: Item) => void;
  handleCloseModal: (bool: boolean) => void;
  onDelete: (itemId: string) => void;
}

const EditItemForm: React.FC<Props> = ({
  editItem,
  onUpdateItem,
  handleCloseModal,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<Item>();
  const [deleteItemConfirm, setDeleteItemConfirm] = useState(false);
  const [addPermissibleModalOpen, setCreateItemModalOpen] = useState(false);
  const [showPermissibleValueForm, setShowPermissibleValueForm] =
    useState(false);
  const language = localStorage.getItem("i18nextLng") || "en";

  useEffect(() => {
    // itemProps.map((value, index) => (
    //   setValue(value.key, Object.keys(editItem).find(key => editItem[key] === value.key))
    // ))

    setValue("dataIndicatorName", editItem.dataIndicatorName);
    setValue("hditName", editItem.hditName);
    setValue("metadataItemType", editItem.metadataItemType);
    setValue("technicalName", editItem.technicalName);
    setValue("synonyms", editItem.synonyms);
    setValue("dataRegistrationIdentifier", editItem.dataRegistrationIdentifier);
    setValue("registrationStatus", editItem.registrationStatus);
    setValue("definition", editItem.definition);
    setValue("dataElementConcept", editItem.dataElementConcept);
    setValue("representationClass", editItem.representationClass);
    setValue("datatype", editItem.datatype);
    setValue("format", editItem.format);
    setValue("maximumCharacterLength", editItem.maximumCharacterLength);

    //add permissible values to fields array???
    setValue("permissibleValues", editItem.permissibleValues);
    setValue("guideForUse", editItem.guideForUse);
    setValue("comments", editItem.comments);
    setValue("submittingOrganisation", editItem.submittingOrganisation);
    setValue("resourceApprovalDocuments", editItem.resourceApprovalDocuments);

    setValue("dataIndicatorNameMn", editItem.dataIndicatorNameMn);
    setValue("hditNameMn", editItem.hditNameMn);
    setValue("metadataItemTypeMn", editItem.metadataItemTypeMn);
    setValue("technicalNameMn", editItem.technicalNameMn);
    setValue("synonymsMn", editItem.synonymsMn);
    setValue(
      "dataRegistrationIdentifierMn",
      editItem.dataRegistrationIdentifierMn
    );
    setValue("registrationStatusMn", editItem.registrationStatusMn);
    setValue("definitionMn", editItem.definitionMn);
    setValue("dataElementConceptMn", editItem.dataElementConceptMn);
    setValue("representationClassMn", editItem.representationClassMn);
    setValue("datatypeMn", editItem.datatypeMn);
    setValue("formatMn", editItem.formatMn);
    setValue("maximumCharacterLengthMn", editItem.maximumCharacterLengthMn);

    //add permissible values to fields array???
    setValue("permissibleValuesMn", editItem.permissibleValuesMn);
    setValue("guideForUseMn", editItem.guideForUseMn);
    setValue("commentsMn", editItem.commentsMn);
    setValue("submittingOrganisationMn", editItem.submittingOrganisationMn);
    setValue(
      "resourceApprovalDocumentsMn",
      editItem.resourceApprovalDocumentsMn
    );
  }, [editItem]);

  const onSubmit: SubmitHandler<Item> = (itemData) => {
    updateItem(itemData, editItem._id)
      .then((data) => {
        onUpdateItem(data.item._id, data.item);
        handleCloseModal(false);
      })
      .catch((error) => console.log(error));
  };

  const createPdf = (item: Item, language: string) => {
    getPDF(item, language).then((response) => {
      printPDF(response, item.dataRegistrationIdentifier);
    });
  };

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "permissibleValues"
  // });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formContainer">
          <div className="leftForm">
            <label>Data/Indicator name</label>
            <Controller
              name="dataIndicatorName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>HDIT name</label>
            <Controller
              name="hditName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Metadata item type</label>
            <Controller
              name="metadataItemType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Technical name</label>
            <Controller
              name="technicalName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Synonyms</label>
            <Controller
              name="synonyms"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Data registration indentifier</label>
            <Controller
              name="dataRegistrationIdentifier"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Registration status</label>
            <Controller
              name="registrationStatus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Definition</label>
            <Controller
              name="definition"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Data element concept</label>
            <Controller
              name="dataElementConcept"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Representation class</label>
            <Controller
              name="representationClass"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Datatype</label>
            <Controller
              name="datatype"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Format</label>
            <Controller
              name="format"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Maximum character length</label>
            <Controller
              name="maximumCharacterLength"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Permissible values</label>
            <Controller
              name="permissibleValues"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Guide for use</label>
            <Controller
              name="guideForUse"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Comments</label>
            <Controller
              name="comments"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Submitting organisation</label>
            <Controller
              name="submittingOrganisation"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Resource approval documents</label>
            <Controller
              name="resourceApprovalDocuments"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
          </div>

          <div className="rightForm">
            <label>Өгөгдлийн нэр</label>
            <Controller
              name="dataIndicatorNameMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>ЭМӨМТ стандартын нэр</label>
            <Controller
              name="hditNameMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Мета өгөгдлийн төрөл</label>
            <Controller
              name="metadataItemTypeMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Техникийн нэр</label>
            <Controller
              name="technicalNameMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Ижил утгатай нэрс</label>
            <Controller
              name="synonymsMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Өгөгдлийн бүртгэлийн дугаар</label>
            <Controller
              name="dataRegistrationIdentifierMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Бүртгэлийн байдал</label>
            <Controller
              name="registrationStatusMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Тодорхойлолт</label>
            <Controller
              name="definitionMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Дата элементийн ойлголт</label>
            <Controller
              name="dataElementConceptMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Илэрхийлэх ангилал</label>
            <Controller
              name="representationClassMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Өгөгдлийн төрөл</label>
            <Controller
              name="datatypeMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Формат</label>
            <Controller
              name="formatMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Үсгийн хамгийн урт хэмжээ</label>
            <Controller
              name="maximumCharacterLengthMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Авч болох утга</label>
            <Controller
              name="permissibleValuesMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>Хэрэглэх заавар</label>
            <Controller
              name="guideForUseMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Нэмэлт тайлбар</label>
            <Controller
              name="commentsMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextareaAutosize {...field} className="textArea" />
              )}
            />
            <label>
              Тухайн нэр томьёог оруулахаар санал оруулсан байгууллага
            </label>
            <Controller
              name="submittingOrganisationMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
            <label>Гарал үүсэл/ Үндсэн баримт </label>
            <Controller
              name="resourceApprovalDocumentsMn"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input {...field} className="materialUIInput" />
              )}
            />
          </div>
        </div>
        <div className="dialogFooter">
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            {!deleteItemConfirm ? (
              <Button
                variant="outlined"
                color="error"
                type="button"
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteItemConfirm(true)}
              >
                {t("button_delete")}
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                type="button"
                startIcon={<DeleteIcon />}
                onClick={() => onDelete(editItem._id)}
              >
                {t("button_delete_confirm")}
              </Button>
            )}
            <Button
              variant="outlined"
              type="button"
              startIcon={<PictureAsPdfIcon />}
              onClick={(e) => createPdf(editItem, language)}
            >
              {t("button_pdf")}
            </Button>
            <div style={{ flex: "1 0 0" }} />
            <Button variant="contained" type="submit">
              {t("button_update")}
            </Button>
            <Button onClick={() => handleCloseModal(false)} variant="outlined">
              {t("button_cancel")}
            </Button>
          </Stack>
        </div>
      </form>
    </div>
  );
};

export default EditItemForm;
