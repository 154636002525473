import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "../../../models/Item";
import { deleteItem, getItems } from "../../../services/data";
import AddItemForm from "../form/AddItemForm";
import EditItemForm from "../form/EditItemForm";
import ItemTable from "../table/ItemTable";
import style from "./style.module.css";

const initCurrentItem: Item = {
  dataIndicatorName: "",
  hditName: "",
  metadataItemType: "",
  technicalName: "",
  synonyms: "",
  dataRegistrationIdentifier: "",
  registrationStatus: "",
  definition: "",
  dataElementConcept: "",
  representationClass: "",
  datatype: "",
  format: "",
  maximumCharacterLength: "",
  permissibleValues: "",
  guideForUse: "",
  comments: "",
  submittingOrganisation: "",
  resourceApprovalDocuments: "",
  _id: "",
  dataIndicatorNameMn: "",
  hditNameMn: "",
  metadataItemTypeMn: "",
  technicalNameMn: "",
  synonymsMn: "",
  dataRegistrationIdentifierMn: "",
  registrationStatusMn: "",
  definitionMn: "",
  dataElementConceptMn: "",
  representationClassMn: "",
  datatypeMn: "",
  formatMn: "",
  maximumCharacterLengthMn: "",
  permissibleValuesMn: "",
  guideForUseMn: "",
  commentsMn: "",
  submittingOrganisationMn: "",
  resourceApprovalDocumentsMn: "",
  patientIdentifierDataSet: false,
  practitionerIdentifierDataSet: false,
  organizationIdentifierDataSet: false,
  patientSummaryDataSet: false,
  outpatientCareSummaryDataSet: false,
  inpatientCareSummaryDataSet: false,
  laboratoryResultsDataSet: false,
  imagingResultsDataSet: false,
  electronicReferralDataSet: false,
  electronicPrescriptionDataSet: false,
  immunizationNoteDataSet: false,
  emergencyCareNoteDataSet: false,
  electronicPhysicianOrderDataSet: false,
  attachedSpecialRecordDataSet: false,
};
const Home: React.FC = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<Item[]>([]);
  const [editItem, setEditItem] = useState(initCurrentItem);

  const [createItemModalOpen, setCreateItemModalOpen] = useState(false);
  const handleCreateItemModalOpen = () => setCreateItemModalOpen(true);
  const handleCreateItemModalClose = () => setCreateItemModalOpen(false);

  const [updateItemModalOpen, setUpdateItemModalOpen] = useState(false);
  const handleUpdateItemModalOpen = () => setUpdateItemModalOpen(true);
  const handleUpdateItemModalClose = () => {
    setUpdateItemModalOpen(false);
  };

  useEffect(() => {
    let isCancelled = false;

    getItems()
      .then((data) => {
        if (!isCancelled) {
          setItems(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  const onAddItem = (newItem: Item) => {
    setItems([...items, newItem]);
  };

  const onCurrentItem = (item: Item) => {
    setEditItem(item);
    handleUpdateItemModalOpen();
  };

  const onDeleteItem = (itemId: string) => {
    deleteItem(itemId).then((data) => {
      setItems(items.filter((i) => i._id !== itemId));
      handleUpdateItemModalClose();
    });
  };

  const onUpdateItem = (id: string, newItem: Item) => {
    setItems(items.map((i) => (i._id === id ? newItem : i)));
  };

  const onSearchItem = (filteredItems: Item[]) => {
    setItems(filteredItems);
  };

  const onModalClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setCreateItemModalOpen(false);
    }
  };

  return (
    <div className={style.addItemHome}>
      <div className={style.button}>
        <Button variant="contained" onClick={handleCreateItemModalOpen}>
          {t("button_create")}
        </Button>
      </div>
      <Dialog
        open={createItemModalOpen}
        onClose={(event, reason) => {
          console.log(reason);
          if (reason !== "backdropClick") {
            onModalClose(event, reason);
          }
        }}
        fullWidth
        disableEscapeKeyDown
        maxWidth="lg"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <AddItemForm
              onAddItem={onAddItem}
              handleCloseModal={handleCreateItemModalClose}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={updateItemModalOpen}
        onClose={(event, reason) => {
          console.log(reason);
          if (reason !== "backdropClick") {
            onModalClose(event, reason);
          }
        }}
        fullWidth
        disableEscapeKeyDown
        maxWidth="lg"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <EditItemForm
              editItem={editItem}
              onUpdateItem={onUpdateItem}
              handleCloseModal={handleUpdateItemModalClose}
              onDelete={onDeleteItem}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ItemTable
        items={items}
        onEdit={onCurrentItem}
        onDelete={onDeleteItem}
        onSearchItem={onSearchItem}
      />
    </div>
  );
};

export default Home;
