import './style.css'


interface Props {
    children?: React.ReactNode;
}

const DarkButtonBig: React.FC<Props> = ({
    children,
}) => {

    return (
        <div className="darkBig">
            <p className="darkBigText">
            {children}
            </p>
        </div>
    );
}

export default DarkButtonBig;