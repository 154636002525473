const ItemProp = {
    dataIndicatorName: "",
    hditName: "",
    metadataItemType: "",
    technicalName: "",
    synonyms: "",
    dataRegistrationIdentifier: "",
    registrationStatus: "",
    definition: "",
    dataElementConcept: "",
    representationClass: "",
    datatype: "",
    format: "",
    maximumCharacterLength: "",
    // permissibleValues: [],
    permissibleValues: "",
    guideForUse: "",
    comments: "",
    submittingOrganisation: "",
    resourceApprovalDocuments: ""
}

export default ItemProp;