import react from "react";
import css from "./style.module.css";
interface Props {
  src: string | undefined;
}

const Logo = (props: Props) => {
  return (
    <div className={css.container}>
      <img className={css.logo} src={props.src}></img>
    </div>
  );
};

export default Logo;
