import React, { useState } from "react";
import css from "../style.module.css";
import HealthDataListHead from "../../../components/hdd-documents/contentTable/HealthDataListHead";
import HealthDataList from "../../../components/hdd-documents/contentTable/HealthDataList";
import NormalButton from "../../../components/hdd-documents/buttons/NormalButton";
import { Item } from "../../../models/Item";
import ItemSearch from "../../../components/hdd-documents/searchbars";
import { useTranslation } from "react-i18next";
import { getPDF, printPDF } from "../../../services/data";

interface Props {
  items: Item[];
  onSearchItem: (items: Item[]) => void;
  onViewOneItem: (item: Item) => void;
}

const DataViewSection: React.FC<Props> = ({
  items,
  onViewOneItem,
  onSearchItem,
}) => {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searched, setSearched] = useState<string>("");
  const [filteredItems, setFilteredItems] = useState<Item[]>(items);
  const language = localStorage.getItem("i18nextLng") || "en";
  var isMongolian: boolean = false;

  if (language === "mn") {
    isMongolian = true;
  }

  const handleAddRows = () => {
    setRowsPerPage(rowsPerPage + 10);
  };

  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);

    const filteredRows = items.filter((row) => {
      return (
        row.dataIndicatorName
          .toLowerCase()
          .includes(searchedVal.toLowerCase()) ||
        row.hditName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        (row.dataIndicatorNameMn &&
          row.dataIndicatorNameMn
            .toLowerCase()
            .includes(searchedVal.toLowerCase()))
      );
    });

    setFilteredItems(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const createPdf = (item: Item, language: string) => {
    getPDF(item, language).then((response) => {
      printPDF(response, item.dataRegistrationIdentifier);
    });
  };

  return (
    <div className={css.centering}>
      <div className={css.itemList}>
        <p className={css.title} style={{ marginLeft: "32px" }}>
          {t("HEALTH_DATA")}
        </p>
        <ItemSearch
          onCancelSearch={cancelSearch}
          onRequestSearch={requestSearch}
        />
        <HealthDataListHead />
        {(filteredItems.length > 0 || searched != "" ? filteredItems : items)
          .slice(0, rowsPerPage)
          .map((item, index) => (
            <HealthDataList
              onDownloadPDF={(event: React.MouseEvent<HTMLButtonElement>) =>
                createPdf(item, language)
              }
              onClick={() => onViewOneItem(item)}
              name={
                isMongolian ? item.dataIndicatorNameMn : item.dataIndicatorName
              }
              code={
                isMongolian
                  ? item.dataRegistrationIdentifierMn
                  : item.dataRegistrationIdentifier
              }
            ></HealthDataList>
          ))}
        <div className={css.buttonRow}>
          <div className={css.buttonCol}>
            {/* <NormalButton onClick={handleAddRows}>
              Бүгдийг нь татах
            </NormalButton> */}
            <NormalButton onClick={handleAddRows}>{t("seeMore")}</NormalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataViewSection;
