import { useTranslation } from "react-i18next";
import "./style.css";

interface Props {
  children?: React.ReactNode;
  //onClick: () => void;
}

const DarkButtonMedium: React.FC<Props> = ({
  children,
  //onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="darkMedium">
      <p className="darkMediumText">{children}</p>
    </div>
  );
};

export default DarkButtonMedium;
