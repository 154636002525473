import React, { useEffect, useState } from "react";
import css from "./style.module.css";
import SideBar from "../../components/SideBar";
import NormalButton from "../../components/hdd-documents/buttons/NormalButton";
import DataSet from "../../components/hdd-documents/buttons/DataSet";
import HealthDataTable from "../../components/hdd-documents/contentTable/healthDataTable";
import { Item } from "../../models/Item";
import { getItems } from "../../services/data";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import DataViewSection from "./component/DataViewSection";
import { getDataSetPDF, printPDF } from "../../services/data";

const initCurrentItem: Item = {
  dataIndicatorName: "",
  hditName: "",
  metadataItemType: "",
  technicalName: "",
  synonyms: "",
  dataRegistrationIdentifier: "",
  registrationStatus: "",
  definition: "",
  dataElementConcept: "",
  representationClass: "",
  datatype: "",
  format: "",
  maximumCharacterLength: "",
  permissibleValues: "",
  guideForUse: "",
  comments: "",
  submittingOrganisation: "",
  resourceApprovalDocuments: "",
  _id: "",
  dataIndicatorNameMn: "",
  hditNameMn: "",
  metadataItemTypeMn: "",
  technicalNameMn: "",
  synonymsMn: "",
  dataRegistrationIdentifierMn: "",
  registrationStatusMn: "",
  definitionMn: "",
  dataElementConceptMn: "",
  representationClassMn: "",
  datatypeMn: "",
  formatMn: "",
  maximumCharacterLengthMn: "",
  permissibleValuesMn: "",
  guideForUseMn: "",
  commentsMn: "",
  submittingOrganisationMn: "",
  resourceApprovalDocumentsMn: "",
  patientIdentifierDataSet: false,
  practitionerIdentifierDataSet: false,
  organizationIdentifierDataSet: false,
  patientSummaryDataSet: false,
  outpatientCareSummaryDataSet: false,
  inpatientCareSummaryDataSet: false,
  laboratoryResultsDataSet: false,
  imagingResultsDataSet: false,
  electronicReferralDataSet: false,
  electronicPrescriptionDataSet: false,
  immunizationNoteDataSet: false,
  emergencyCareNoteDataSet: false,
  electronicPhysicianOrderDataSet: false,
  attachedSpecialRecordDataSet: false,
};

const HealthData: React.FC = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [viewOneItem, setViewOneItem] = useState(initCurrentItem);
  const [itemModalOpen, setItemModalOpen] = useState(false);

  const handleItemModalOpen = () => setItemModalOpen(true);
  const handleOk = () => setItemModalOpen(false);
  const handleCancel = () => setItemModalOpen(false);

  const language = localStorage.getItem("i18nextLng") || "en";

  const { t } = useTranslation();

  var dataSetList = [
    "patientIdentifierDataSet",
    "practitionerIdentifierDataSet",
    "organizationIdentifierDataSet",
    "patientSummaryDataSet",
    "outpatientCareSummaryDataSet",
    "inpatientCareSummaryDataSet",
    "laboratoryResultsDataSet",
    "imagingResultsDataSet",
    "electronicReferralDataSet",
    "electronicPrescriptionDataSet",
    "immunizationNoteDataSet",
    "emergencyCareNoteDataSet",
    "electronicPhysicianOrderDataSet",
    "attachedSpecialRecordDataSet",
  ];

  useEffect(() => {
    let isCancelled = false;

    getItems()
      .then((data) => {
        if (!isCancelled) {
          setItems(data);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      isCancelled = true;
    };
  }, []);

  const onSearchItem = (filteredItems: Item[]) => {
    setItems(filteredItems);
  };

  const onViewOneItem = (item: Item) => {
    setViewOneItem(item);
    handleItemModalOpen();
  };

  const createPdf = (idetifier: string, language: string) => {
    getDataSetPDF(idetifier, language).then((response) => {
      printPDF(response, idetifier);
    });
  };

  return (
    <div className={css.container}>
      <div className={css.sidebar}>
        <SideBar />
      </div>
      <div className={css.dataList}>
        <DataViewSection
          onViewOneItem={onViewOneItem}
          onSearchItem={onSearchItem}
          items={items}
        ></DataViewSection>
        <Modal
          width={864}
          title={viewOneItem.dataIndicatorName}
          visible={itemModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("close")}
            </Button>,
          ]}
        >
          <HealthDataTable item={viewOneItem}></HealthDataTable>
        </Modal>
      </div>
      <div className={css.dataSet}>
        <p className={css.title}>{t("dataSet")}</p>
        {dataSetList.map((dataSet) => (
          <DataSet onDownloadPDF={() => createPdf(dataSet, language)}>
            {dataSet}
          </DataSet>
        ))}
      </div>
    </div>
  );
};

export default HealthData;
