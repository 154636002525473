import style from "./style.module.css";

interface Props {
  name: string;
  active: boolean;
}

const LanguageLogo: React.FC<Props> = ({ active, name }) => {
  return (
    <div className={style.container}>
      <div className={style.box}>
        <p className={active ? style.active : style.inactive}>{name}</p>
      </div>
    </div>
  );
};

export default LanguageLogo;
