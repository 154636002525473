import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
  // onClick: () => void;
  path: string;
}

const DarkButtonSmall: React.FC<Props> = ({ children, path }) => {
  const { t } = useTranslation();

  return (
    <div className={"darkSmall"}>
      <Link to={path}>
        <p className={"darkSmallText"}>{children}</p>
      </Link>
    </div>
  );
};

export default DarkButtonSmall;
