import { useEffect, useState } from "react";
import { Item } from "../../../../models/Item";
import { useTranslation } from "react-i18next";
import style from "./style.module.css";

interface Props {
  item: Item;
}

const HealthDataTable: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation();
  let permisibleValueList: any[] = [];
  const selected = localStorage.getItem("i18nextLng") || "en";
  var isMongolian: boolean = false;

  if (selected === "mn") {
    isMongolian = true;
  }

  var data: string[] = [];

  if (item.permissibleValuesMn && isMongolian) {
    data = item.permissibleValuesMn.split(/\r?\n/);
  }

  if (item.permissibleValues && !isMongolian) {
    data = item.permissibleValues.split(/\r?\n/);
  }

  // let data: string[] = isMongolian
  //   ? if (item.permissibleValuesMn){item.permissibleValuesMn.split(/\r?\n/)}
  //   : item.permissibleValues.split(/\r?\n/);

  data.slice(1).forEach((element) => {
    permisibleValueList.push(
      <tr>
        <th></th>
        <td>{element}</td>
      </tr>
    );
  });
  return (
    <div className={style.container}>
      <table>
        <tr>
          <th>{t("hdd_form_data_indicatior_name")}</th>
          <td>
            {isMongolian ? item.dataIndicatorNameMn : item.dataIndicatorName}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_hdit_name")}</th>
          <td>{isMongolian ? item.hditNameMn : item.hditName}</td>
        </tr>
      </table>
      <div className={style.header}>
        {t("IDENTIFYING_AND_DEFINITIONAL_ATTRIBUTES")}
      </div>
      <table className={style.table}>
        <tr>
          <th>{t("hdd_form_metadata_item_type")}</th>
          <td>
            {isMongolian ? item.metadataItemTypeMn : item.metadataItemType}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_technical_name")}</th>
          <td>{isMongolian ? item.technicalNameMn : item.technicalName}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_synonyms")}</th>
          <td>{isMongolian ? item.synonymsMn : item.synonyms}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_data_registration_identifier")}</th>
          <td>
            {isMongolian
              ? item.dataRegistrationIdentifierMn
              : item.dataRegistrationIdentifier}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_registration_status")}</th>
          <td>
            {isMongolian ? item.registrationStatusMn : item.registrationStatus}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_definition")}</th>
          <td>{isMongolian ? item.definitionMn : item.definition}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_data_element_concept")}</th>
          <td>
            {isMongolian ? item.dataElementConceptMn : item.dataElementConcept}
          </td>
        </tr>
      </table>
      <div className={style.header}>{t("VALUE_DOMAIN_ATTRIBUTES")}</div>
      <table className={style.table}>
        <tr>
          <th style={{ paddingLeft: "44px" }}>
            {t("REPRESENTATIONAL_ATTRIBUTES")}
          </th>
          <td></td>
        </tr>
        <tr>
          <th>{t("hdd_form_representation_class")}</th>
          <td>
            {isMongolian
              ? item.representationClassMn
              : item.representationClass}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_datatype")}</th>
          <td>{isMongolian ? item.datatypeMn : item.datatype}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_format")}</th>
          <td>{isMongolian ? item.formatMn : item.format}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_max_char_length")}</th>
          <td>
            {isMongolian
              ? item.maximumCharacterLength
              : item.maximumCharacterLength}
          </td>
        </tr>
        {item.permissibleValues ? (
          <>
            <tr>
              <th>{t("hdd_form_permissible_values")}</th>
              <td>{data[0]}</td>
            </tr>
            {permisibleValueList}
          </>
        ) : (
          <></>
        )}
      </table>
      <div className={style.header}>{t("Collection_and_usage_attributes")}</div>
      <table className={style.table}>
        <tr>
          <th>{t("hdd_form_guide_for_use")}</th>
          <td>{isMongolian ? item.guideForUseMn : item.guideForUse}</td>
        </tr>
        <tr>
          <th>{t("hdd_form_comments")}</th>
          <td>{isMongolian ? item.commentsMn : item.comments}</td>
        </tr>
      </table>
      <div className={style.header}>{t("Source_and_reference_attributes")}</div>
      <table className={style.table}>
        <tr>
          <th>{t("hdd_form_submitting_org")}</th>
          <td>
            {isMongolian
              ? item.submittingOrganisationMn
              : item.submittingOrganisation}
          </td>
        </tr>
        <tr>
          <th>{t("hdd_form_resource_approval_documents")}</th>
          <td>
            {isMongolian
              ? item.resourceApprovalDocumentsMn
              : item.resourceApprovalDocuments}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default HealthDataTable;
