import style from "./style.module.css";
import LanguageLogo from "../Languagelogo";

interface Props {
  children?: React.ReactNode;
  onClick: () => void;
  LanguageKey: string;
}

const PopOver: React.FC<Props> = ({ children, onClick, LanguageKey }) => {
  return (
    <button onClick={onClick} className={style.row}>
      <div>
        <LanguageLogo active={true} name={LanguageKey}></LanguageLogo>
      </div>
      <p>{children}</p>
    </button>
  );
};

export default PopOver;
