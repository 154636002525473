import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { createItem } from "../../../services/data";

import "./Form.css";
import { Item } from "../../../models/Item";
import ItemProp from "../../../helper/ItemProp";
import { Button, Stack, TextareaAutosize, Input, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";


interface Props {
  onAddItem: (item: Item) => void;
  handleCloseModal: (bool: boolean) => void;
}

const AddItemForm: React.FC<Props> = ({ onAddItem, handleCloseModal }) => {

  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<Item>();

  const onSubmit: SubmitHandler<Item> = itemData => {

    createItem(itemData).then(data => {
      reset(ItemProp);
      onAddItem(data.item);
    }).catch((error) => console.log(error));

  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="formContainer">
          <div className="leftForm">
            <label>{t("hdd_form_data_indicatior_name")}</label>
            <Controller name="dataIndicatorName" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_hdit_name")}</label>
            <Controller name="hditName" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_metadata_item_type")}</label>
            <Controller name="metadataItemType" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_technical_name")}</label>
            <Controller name="technicalName" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_synonyms")}</label>
            <Controller name="synonyms" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>{t("hdd_form_data_registration_identifier")}</label>
            <Controller name="dataRegistrationIdentifier" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_registration_status")}</label>
            <Controller name="registrationStatus" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_definition")}</label>
            <Controller name="definition" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>{t("hdd_form_data_element_concept")}</label>
            <Controller name="dataElementConcept" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_representation_class")}</label>
            <Controller name="representationClass" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_datatype")}</label>
            <Controller name="datatype" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_format")}</label>
            <Controller name="format" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_max_char_length")}</label>
            <Controller name="maximumCharacterLength" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_permissible_values")}</label>
            <Controller name="permissibleValues" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>{t("hdd_form_guide_for_use")}</label>
            <Controller name="guideForUse" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_comments")}</label>
            <Controller name="comments" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>{t("hdd_form_submitting_org")}</label>
            <Controller name="submittingOrganisation" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>{t("hdd_form_resource_approval_documents")}</label>
            <Controller name="resourceApprovalDocuments" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Үйлчлүүлэгчийг ялган таних өгөгдлийн багц</label>
            <Controller name="patientIdentifierDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Эмнэлгийн мэргэжилтнийг ялган таних өгөгдлийн багц</label>
            <Controller name="practitionerIdentifierDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Байгууллагыг ялган таних өгөгдлийн багц</label>
            <Controller name="organizationIdentifierDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Өвчтөний хураангуй өгөгдлийн багц</label>
            <Controller name="patientSummaryDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Амбулаторын үзлэгийн тэмдэглэлийн багц</label>
            <Controller name="outpatientCareSummaryDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Эмнэлэгт хэвтсэн тухай тэмдэглэлийн багц</label>
            <Controller name="inpatientCareSummaryDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Цахим жорын өгөгдлийн багц </label>
            <Controller name="electronicPrescriptionDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
          </div>

          <div className="rightForm">
            <label>Өгөгдлийн нэр</label>
            <Controller name="dataIndicatorNameMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>ЭМӨМТ стандартын нэр</label>
            <Controller name="hditNameMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Мета өгөгдлийн төрөл</label>
            <Controller name="metadataItemTypeMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Техникийн нэр</label>
            <Controller name="technicalNameMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Ижил утгатай нэрс</label>
            <Controller name="synonymsMn" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>Өгөгдлийн бүртгэлийн дугаар</label>
            <Controller name="dataRegistrationIdentifierMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Бүртгэлийн байдал</label>
            <Controller name="registrationStatusMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Тодорхойлолт</label>
            <Controller name="definitionMn" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>Дата элементийн ойлголт</label>
            <Controller name="dataElementConceptMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Илэрхийлэх ангилал</label>
            <Controller name="representationClassMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Өгөгдлийн төрөл</label>
            <Controller name="datatypeMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Формат</label>
            <Controller name="formatMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Үсгийн хамгийн урт хэмжээ</label>
            <Controller name="maximumCharacterLengthMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Авч болох утга</label>
            <Controller name="permissibleValuesMn" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>Хэрэглэх заавар</label>
            <Controller name="guideForUseMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Нэмэлт тайлбар</label>
            <Controller name="commentsMn" control={control} defaultValue="" render={({ field }) => <TextareaAutosize {...field} className="textArea" />} />
            <label>Тухайн нэр томьёог оруулахаар санал оруулсан байгууллага</label>
            <Controller name="submittingOrganisationMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Гарал үүсэл/ Үндсэн баримт </label>
            <Controller name="resourceApprovalDocumentsMn" control={control} defaultValue="" render={({ field }) => <Input {...field} className="materialUIInput" />} />
            <label>Цахим шилжүүлгийн өгөгдлийн багц </label>
            <Controller name="electronicReferralDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Дүрс оношилгооны шинжилгээний үр дүнгийн өгөгдлийн багц</label>
            <Controller name="imagingResultsDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Лабораторийн шинжилгээний үр дүнгийн өгөгдлийн багц</label>
            <Controller name="laboratoryResultsDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Дархлаажуулалтын тэмдэглэлийн өгөгдлийн багц</label>
            <Controller name="immunizationNoteDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Яаралтай тусламж үзүүлсэн тэмдэглэлийн өгөгдлийн багц</label>
            <Controller name="emergencyCareNoteDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Эмчийн цахим захиалгын өгөгдлийн багц</label>
            <Controller name="electronicPhysicianOrderDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
            <label>Хавсаргасан тусгай файлуудын өгөгдлийн багц</label>
            <Controller name="attachedSpecialRecordDataSet" control={control} defaultValue={false} render={({ field }) => <Checkbox {...field}/>} />
          </div>
        </div>
        <div className="dialogFooter">
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <div style={{ flex: '1 0 0' }} />
            <Button variant="contained" type="submit">{t("button_create")}</Button>
            <Button onClick={() => handleCloseModal(false)} variant="outlined">{t("button_cancel")}</Button>
          </Stack>
        </div>
      </form>
    </div>
  );
};

export default AddItemForm;
