import React from "react";
import css from "./style.module.css";
import Logo from "../../components/hdd-documents/Logo";
import worldBankLogo from "../../assets/images/worldBankLogo.png";
import ministryOfHealth from "../../assets/images/ministryOfHealth.png";
import eHealth from "../../assets/images/eHealth.png";
import LanguageSelect from "../../components/hdd-documents/LanguageSelect";
import SearchBar from "../../components/hdd-documents/searchbars";

const MyHeader: React.FC = () => {
  return (
    <div className={css.header}>
      <div className={css.logos}>
        <Logo src={ministryOfHealth} />
        <Logo src={eHealth} />
        <Logo src={worldBankLogo} />
      </div>
      <div className={css.language}>
        {/* <SearchBar/> */}
        <LanguageSelect />
      </div>
    </div>
  );
};

export default MyHeader;
