import { useTranslation } from "react-i18next";
import "./style.css";

interface listProps {
  children?: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const NormalButton: React.FC<listProps> = ({ children, onClick }) => {
  const { t } = useTranslation();

  return (
    <button onClick={onClick} className={"normalButton"}>
      <p className="normalButtonText">{children}</p>
    </button>
  );
};

export default NormalButton;
