import { useTranslation } from "react-i18next";
import { Input, Button, Card, Divider, Col, Row } from "antd";
import "./style.css";
import HealthDataList from "./HealthDataList";

const HealthDataListHead: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="dataHeader">
      <p style={{ width: "55%" }} className="dataHeaderText">
        {t("hdd_form_data_indicatior_name")}
      </p>
      <p style={{ width: "45%" }} className="dataHeaderText">
        {t("hdd_form_data_registration_identifier")}
      </p>
    </div>
  );
};

export default HealthDataListHead;
