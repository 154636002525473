import {Input } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
    onRequestSearch: (search: string) => void;
    onCancelSearch: (cancel: boolean) => void;
}

const ItemSearch: React.FC<Props> = ({ onRequestSearch }) => {

    const { t } = useTranslation();

    return (
        <div>
            <Input placeholder={t("placeholder_search")} onChange={e => onRequestSearch(e.target.value)} />
        </div>
    )

}

export default ItemSearch;