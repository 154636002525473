import React from "react";
import DarkButtonBig from "../../components/hdd-documents/buttons/DarkButtonBig";
import CyanButton from "../../components/hdd-documents/buttons/CyanButton";
import css from "./style.module.css";
import { useTranslation } from "react-i18next";

const MyHome: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={css.background}>
      <div>
        <p className={css.title}>{t("Health_Data_dictionary")}</p>
      </div>
      <div>
        <p className={css.description}>{t("financed_by_the_World_Bank")}​</p>
      </div>
      <div className={css.row}>
        <div className={css.col}>
          <DarkButtonBig>{t("Health_Data_Dictionary_V2")}</DarkButtonBig>
          <CyanButton path="/health_data">{t("Health_Data")}</CyanButton>
          <CyanButton path="/health_data">
            {t("Nationwide_Indicators")}
          </CyanButton>
          <DarkButtonBig>{t("HEALTH_DATA_DICTIONOARY_1")}</DarkButtonBig>
        </div>
        <div className={css.col}>
          <DarkButtonBig>{t("TERMINOLOGY_SYSYTEMS")}</DarkButtonBig>
          <CyanButton path="/health_data">
            {t("International_Classification_Diseases_10th_Revision")}​
          </CyanButton>
          <CyanButton path="/health_data">
            {t("International_Classification_Diseases_9th_Revision")}​
          </CyanButton>
          <CyanButton path="/health_data">
            {t("Logical_Observation_INC")}​
          </CyanButton>
          <CyanButton path="/health_data">
            {t("Systemized_Nomenclature_of_Medicine")}
          </CyanButton>
          <CyanButton path="/health_data">{t("MON_HDD")}</CyanButton>
          <CyanButton path="/health_data">{t("Glossary_of_Terms")}</CyanButton>
          {/* <DarkButtonBig>
                {t("Health_Data_Dictionary_V2")}
                </DarkButtonBig>
                <CyanButton path="/health_data">
                {t("Health_Data")}
                </CyanButton>
                <CyanButton path="/national_indicators">
                {t("Nationwide_Indicators")}
                </CyanButton>
                <DarkButtonBig>
                {t("HEALTH_DATA_DICTIONOARY_1")}
                </DarkButtonBig>
                </div>
                <div className={css.col}>
                <DarkButtonBig>
                {t("TERMINOLOGY_SYSYTEMS")}
                </DarkButtonBig>
                <CyanButton path="/icd10">
                {t("International_Classification_Diseases_10th_Revision")}
                ​</CyanButton>
                <CyanButton path="/icd9">
                {t("International_Classification_Diseases_9th_Revision")}
                ​</CyanButton>
                <CyanButton path="/loinc">
                {t("Logical_Observation_INC")}
                ​</CyanButton>
                <CyanButton path="/snomed">
                {t("Systemized_Nomenclature_of_Medicine")}
                </CyanButton>
                <CyanButton path="/monhdd">
                {t("MON_HDD")}
                </CyanButton>
                <CyanButton path="">
                {t("Glossary_of_Terms")}
                </CyanButton> */}
        </div>
      </div>
    </div>
  );
};

export default MyHome;
