const dev = {
    REACT_APP_SERVER_URL: "http://localhost:3000",
    REACT_APP_TERM_SERVICE_SERVER_URL: "http://localhost:8080"
}

const prod = {
    REACT_APP_SERVER_URL: "http://103.80.211.50:3000",
    REACT_APP_TERM_SERVICE_SERVER_URL: "http://ec2-13-53-87-14.eu-north-1.compute.amazonaws.com:8080"
}

const config = process.env.REACT_APP_STAGE === 'development'
    ? dev
    : prod;

export default config;

// export default {
//     // REACT_APP_SERVER_URL: "http://localhost:3000"
//     ...config
// }
