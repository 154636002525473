import { Item } from "../models/Item";
import { saveAs } from "file-saver";
import config from "../config";

export async function createItem(formData: Item) {
  return fetch(`${config.REACT_APP_SERVER_URL}/add-item`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  }).then((response) => response.json());
}

export async function getItems() {
  return fetch(`${config.REACT_APP_SERVER_URL}/items`)
    .then((response) => response.json())
    .then((data) => data.items);
}

export async function deleteItem(itemId: String) {
  return fetch(`${config.REACT_APP_SERVER_URL}/delete-item/${itemId}`, {
    method: "DELETE",
  }).then((response) => response.json());
}

export async function updateItem(item: Item, id: string) {
  return fetch(`${config.REACT_APP_SERVER_URL}/edit-item/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(item),
  }).then((response) => response.json());
}

export async function getPDF(formData: Item, language: string) {
  return fetch(`${config.REACT_APP_SERVER_URL}/create-pdf`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...formData, language: language }),
  }).then((response) => response.arrayBuffer());
}
export async function getDataSetPDF(identifier: string, language: string) {
  return fetch(
    `${config.REACT_APP_SERVER_URL}/create-pdf-from-items?identifier=${identifier}&language=${language}`
  ).then((response) => response.arrayBuffer());
}

export async function printPDF(data: any, name: string) {
  const blob = new Blob([data], { type: "application/pdf" });
  saveAs(blob, `${name}.pdf`);
}

export async function searchByCodeOrDisplayNameFilterBySid(
  search: string,
  codeSystems: string[]
) {
  if (codeSystems.length > 0) {
    return await fetch(
      `${config.REACT_APP_TERM_SERVICE_SERVER_URL}/search/concept?query=${search}&sids=${codeSystems}`
    ).then((response) => response.json());
  } else {
    return await fetch(
      `${config.REACT_APP_TERM_SERVICE_SERVER_URL}/search/concept?query=${search}`
    ).then((response) => response.json());
  }
}

export async function paginationToPage(search: string, page: number) {
  return await fetch(
    `${config.REACT_APP_TERM_SERVICE_SERVER_URL}/search/concept?query=${search}&page=${page}`
  ).then((response) => response.json());
}

export async function getChildrenBySid(id: string) {
  return await fetch(
    `${config.REACT_APP_TERM_SERVICE_SERVER_URL}/codesystem/concept/children_of/${id}`
  ).then((response) => response.json());
}

export async function getCodeSystems() {
  return await fetch(
    `${config.REACT_APP_TERM_SERVICE_SERVER_URL}/codesystem`
  ).then((response) => response.json());
}
