import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import style from "./style.module.css"
import LanguageLogo from "./components/Languagelogo";
import PopOver from "./components/Popover";
  
const languageMap:any = {
    en: { label: "English", dir: "ltr", active: true },
    mn: { label: "Монгол", dir: "ltr", active: false }
  };
  
  const LanguageSelect: React.FC = () => {
    const selected = localStorage.getItem("i18nextLng") || "en";
    const { t } = useTranslation();
  
    const [menuAnchor, setMenuAnchor] = useState<any>(null);
    useEffect(() => {
      document.body.dir = languageMap[selected].dir;
    }, [menuAnchor, selected]);

    return (
    <div className={style.row}>
        <div>
            <LanguageLogo active={true} name={selected}></LanguageLogo>
        </div>
        <p>
            {t("select_language")}
        </p>
        <div className={style.icon}>
            <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <div className={style.col}>
            {Object.keys(languageMap)?.map((item) => (
              <PopOver
                LanguageKey={item}
                onClick={() => {
                  console.log(item)
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                {languageMap[item].label}
              </PopOver>
            ))}
        </div>
    </div>
    )
}

export default LanguageSelect;
