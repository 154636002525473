import { useTranslation } from "react-i18next";
import "./style.css";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onDownloadPDF: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const DownloadButton: React.FC<Props> = ({ onDownloadPDF }) => {
  const { t } = useTranslation();

  return (
    <button onClick={onDownloadPDF} className={"download"}>
      <FontAwesomeIcon className={"downloadIcon"} icon={faDownload} />
    </button>
  );
};

export default DownloadButton;
