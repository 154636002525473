import { useTranslation } from "react-i18next";
import { Input} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import "./style.modole.css"
  

interface Props {
    onRequestSearch: (search: string) => void;
    onCancelSearch: (cancel: boolean) => void;
}

const SearchBar: React.FC<Props> = ({ onRequestSearch }) => {

    const { t } = useTranslation();

    return (
    <div className={"container"}>
        <input className="big" 
        onChange={e => onRequestSearch(e.target.value)}
        />
        <SearchOutlined className={"icon"}/>
    </div>
    )
}

export default SearchBar;
