import React from "react";
import css from "./style.module.css";

const Footer: React.FC = () => {
  return (
    <div className={css.footer}>
      <p className={css.p}>© 2021 Monosolution</p>
      <p className={css.p}>Privacy Police</p>
      <p className={css.p}>Terms of Use</p>
    </div>
  );
};

export default Footer;
