import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./assets/locales/en/translation.json";
import translationMN from "./assets/locales/mn/translation.json";

const fallbackLng = ['en'];
const availableLanguages = ['en', 'mn'];

const resources = {
  en: {
    translation: translationEN
  },
  mn: {
    translation: translationMN
  }
};

i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng,
        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;