import React from "react";
import { useTranslation } from "react-i18next";
import css from "./style.module.css";
import DarkButtonSmall from "../hdd-documents/buttons/DarkButtonSmall";
import DarkButtonMedium from "../hdd-documents/buttons/DarkButtonMedium";

const SideBar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={css.sideBar}>
      <DarkButtonMedium>{t("Health_Data_Dictionary_V2")}</DarkButtonMedium>
      <DarkButtonSmall path="/health_data">{t("Health_Data")}</DarkButtonSmall>
      <DarkButtonSmall path="/health_data">
        {t("Nationwide_Indicators")}
      </DarkButtonSmall>
      <DarkButtonMedium>{t("HEALTH_DATA_DICTIONOARY_1")}</DarkButtonMedium>
      <DarkButtonMedium>{t("TERMINOLOGY_SYSYTEMS")}</DarkButtonMedium>
      <DarkButtonSmall path="/health_data">
        {t("International_Classification_Diseases_10th_Revision")}​
      </DarkButtonSmall>
      <DarkButtonSmall path="/health_data">
        {t("International_Classification_Diseases_9th_Revision")}
      </DarkButtonSmall>
      <DarkButtonSmall path="/health_data">
        {t("Logical_Observation_INC")}
      </DarkButtonSmall>
      <DarkButtonSmall path="/health_data">
        {t("Systemized_Nomenclature_of_Medicine")}
      </DarkButtonSmall>
      <DarkButtonSmall path="/health_data">{t("MON_HDD")}</DarkButtonSmall>
      <DarkButtonSmall path="/health_data">
        {t("Glossary_of_Terms")}
      </DarkButtonSmall>
      {/* <DarkButtonMedium>{t("Health_Data_Dictionary_V2")}</DarkButtonMedium>
                <DarkButtonSmall path="/health_data">{t("Health_Data")}</DarkButtonSmall>
                <DarkButtonSmall path="/national_indicators">{t("Nationwide_Indicators")}</DarkButtonSmall>
                <DarkButtonMedium>{t("HEALTH_DATA_DICTIONOARY_1")}</DarkButtonMedium>
                <DarkButtonMedium>{t("TERMINOLOGY_SYSYTEMS")}</DarkButtonMedium>
                <DarkButtonSmall  path="/icd10">{t("International_Classification_Diseases_10th_Revision")}​</DarkButtonSmall>
                <DarkButtonSmall path="/icd9">{t("International_Classification_Diseases_9th_Revision")}</DarkButtonSmall>
                <DarkButtonSmall path="/loinc">{t("Logical_Observation_INC")}</DarkButtonSmall>
                <DarkButtonSmall path="/snomed">{t("Systemized_Nomenclature_of_Medicine")}</DarkButtonSmall>
                <DarkButtonSmall path="/monhdd">{t("MON_HDD")}</DarkButtonSmall>
                <DarkButtonSmall path="/health_data">{t("Glossary_of_Terms")}</DarkButtonSmall> */}
    </div>
  );
};

export default SideBar;
